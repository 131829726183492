import React, { Component } from "react";
import Tippschein from "./Tippschein";
import Geldschein from "./Geldschein";
import { Redirect } from "react-router-dom";
import { Bar } from "react-chartjs-2";

class Dashboard extends Component {
  debounce = (func, wait, immediate) => {
    var timeout;
    return function() {
      var context = this,
        args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };
  handleReload = e => {
    this.props.reload();
  };

  render() {
    if (!this.props.loggedIn) {
      return <Redirect to="/login" />;
    }
    //console.log("Dashboard props", this.props);
    return (
      <div className="Dashboard">
        <div className="moneyDash">
          <div className="moneyDashGeldschein">
            <Geldschein
              money={this.props.user.balance}
              currency={this.props.currency}
            />
          </div>
        </div>
        <button
          className="btnreload"
          id="btnReload"
          onClick={this.handleReload}
        >
          Reload <i className="fas fa-sync-alt" />
        </button>
        <div className="akttippDash">
          <h4>myTipps</h4>
          <div className="akttippDashTippscheine">
            {this.props.aktTipps.map((v, i) => (
              <Tippschein key={i} numbers={v.join(",")} />
            ))}
          </div>
        </div>
        <div className="myWinsDash">
          <h4>myWins</h4>
          <div className="myWinsDashWin">
            <p>Gesamtgewinn letzte Ziehung:</p>
            <h4>
              {this.props.lastDraw.balance} {this.props.currency}
            </h4>
          </div>
          <div className="myWinsDashWin">
            <p>Einzelgewinne der letzten 30 Tipps:</p>
            {this.props.myWins.map((v, i) => (
              <div key={i} className="container">
                Tipp vom {v.ts} Uhr: <strong>{v.gewinn}</strong>{" "}
                {this.props.currency}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
