import React, { Component } from "react";
import Kugel from "./Kugel";

class Tippschein extends Component {
  render() {
    //console.log("Tippschein props", this.props);
    const numbers = this.props.numbers.split(",");
    //console.log(numbers);
    return (
      <div className="Tippschein">
        {numbers.map((v, i) => (
          <Kugel key={i} number={v} />
        ))}
      </div>
    );
  }
}

export default Tippschein;
