import React, { Component } from "react";

class Geldschein extends Component {
  render() {
    //console.log("Gelschein props", this.props);
    return (
      <div className="cssGeldschein">
        <span className="cssGeldscheinText">
          {this.props.money} {this.props.currency}
        </span>
      </div>
    );
  }
}

export default Geldschein;
