import React, { Component } from "react";
import Zahl from "./Zahl";
import axios from "axios";
import { Redirect } from "react-router-dom";

class NeuerTippschein extends Component {
  state = {
    neuerTipp: [],
    message:
      this.props.user.balance < this.props.lotto.cost
        ? "Leider nicht genug money :-( ! Jeden zweiten Tag gibts einen Gratis-Tipp! Viel Glück!"
        : ""
  };
  toggleNumber = e => {
    const neueNummer = e.target.dataset.number;
    const neueNummerIndex = this.state.neuerTipp.indexOf(parseInt(neueNummer));
    if (neueNummerIndex === -1) {
      if (this.state.neuerTipp.length < this.props.lotto.anz) {
        const neueNummer = e.target.dataset.number;
        const neuerTipp = [...this.state.neuerTipp, parseInt(neueNummer)];
        this.setState({
          neuerTipp: neuerTipp
        });
      }
    } else {
      this.setState({
        neuerTipp: this.state.neuerTipp.filter((_, i) => i !== neueNummerIndex)
      });
    }
  };
  sortNumeric = (a, b) => {
    return a - b;
  };
  saveTippschein = e => {
    //Kontostand kontrollieren
    if (this.props.user.balance < this.props.lotto.cost) {
      this.setState({ message: "Nicht genung Money!" });
      return;
    }

    //console.log("saveTippschein neuerTipp", this.state.neuerTipp);
    let neuerTipp = this.state.neuerTipp;
    neuerTipp.sort(this.sortNumeric);
    //console.log("saveTippschein neuerTipp", this.state.neuerTipp);
    axios
      .get("https://sia.gymgmunden.at/rest.php", {
        params: {
          trigger: "saveNewTipp",
          sia_sid: this.props.uid,
          sia_neuerTipp: neuerTipp.join(","),
          sia_cost: this.props.lotto.cost
        }
      })
      .then(res => {
        this.setState({ neuerTipp: [] });
        this.props.user.balance = res.data.data.user.balance;
        this.props.aktTipps = res.data.data.tipps;
        //console.log("saveTippschein OK res", res);
        this.props.updateDashboardData();
      });
  };
  isChosen = v => {
    return this.state.neuerTipp.indexOf(v) !== -1;
  };
  render() {
    if (!this.props.loggedIn) {
      return <Redirect to="/login" />;
    }
    //console.log("NeuerTippschein props", this.props);
    //console.log("NeuerTippschein state", this.state);
    let zahlen = [];
    for (let i = 1; i <= this.props.lotto.max; i++) {
      zahlen.push(i);
    }
    return (
      <div className="cssNeuerTippschein">
        <h4>Neuer Tippschein</h4>
        <div className="cssNeuerTippscheinGrid">
          <div className="cssTippscheinInfo">Bitte tippen...</div>
          <div className="cssTippscheinInfo">
            {" "}
            <div>Ein Tipp kostet</div>
            <div>
              <strong>{this.props.lotto.cost}</strong> {this.props.currency}
            </div>
          </div>
          <div className="cssTippscheinInfo">
            <div>Dein Kontostand</div>
            <div>
              <strong>{this.props.user.balance}</strong> {this.props.currency}
            </div>
          </div>
          {zahlen.map((v, k) => (
            <Zahl
              key={k}
              value={v}
              {...this.state}
              chosen={this.isChosen(v) ? true : false}
              onToggle={this.toggleNumber}
            />
          ))}
          <div className="span3">{this.state.message}</div>
          <button
            className="cssTippscheinSubmit"
            onClick={this.saveTippschein}
            disabled={
              this.state.neuerTipp.length === 3 &&
              this.props.user.balance >= this.props.lotto.cost
                ? ""
                : "disabled"
            }
          >
            {this.state.neuerTipp.length === 3
              ? "Tippschein abgeben"
              : "Bitte " + this.props.lotto.anz + " Zahlen wählen!"}
          </button>
        </div>
      </div>
    );
  }
}

export default NeuerTippschein;
