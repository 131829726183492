import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";

class LoginPage extends Component {
  state = { uid: "", upw: "", autologin: true };
  tryLogin = () => {
    const params = new URLSearchParams();
    params.append("uid", this.state.uid);
    params.append("upw", this.state.upw);
    axios
      .post("https://api.gymgmunden.at/auth01.php", params)
      .then(res => {
        console.log("Result Auth", res);
        localStorage.setItem("sia2019_uid", this.state.uid);
        localStorage.setItem("sia2019_upw", this.state.upw);

        const params = new URLSearchParams();
        console.log("state", this.state);
        params.append("trigger", "getOrSetUser");
        params.append("sia_uid", this.state.uid);
        params.append("sia_first", res.data.erg.givenname);
        params.append("sia_last", res.data.erg.sn);
        params.append("sia_klasse", res.data.erg.klasse);

        axios.post("https://sia.gymgmunden.at/rest.php", params).then(res => {
          this.props.onLogin();
        });
        //console.log("props", this.props);
      })
      .catch(err => {
        console.log("Error Auth", err);
      });
  };

  submitForm = e => {
    e.preventDefault();
    this.tryLogin();
  };
  handleUID = e => {
    //console.log("handleUID", e.target.value);
    this.setState({ uid: e.target.value });
  };
  handleUPW = e => {
    this.setState({ upw: e.target.value });
  };
  componentDidMount = () => {
    //try Autologin
    if (this.state.autologin) {
      const uid = localStorage.getItem("sia2019_uid");
      if (uid !== null && uid.length && uid.length > 1) {
        const upw = localStorage.getItem("sia2019_upw");
        this.setState({ uid: uid, upw: upw }, this.tryLogin);
      }
      this.setState({ autologin: false });
    }
  };
  render() {
    if (this.props.loggedIn) {
      return <Redirect to="/" />;
    } else
      return (
        <div className="LoginPage">
          <div className="LoginForm">
            <p>Melde dich einmalig mit deinen Schul-IT-Benutzerdaten an:</p>
            <div>
              <label htmlFor="uid" />
              <input
                type="text"
                name="uid"
                id="uid"
                placeholder="Benutzername"
                value={this.state.uid}
                onChange={this.handleUID}
              />
            </div>
            <div>
              <label htmlFor="upw" />
              <input
                type="password"
                name="upw"
                id="upw"
                placeholder="Passwort"
                value={this.state.upw}
                onChange={this.handleUPW}
              />
            </div>
            <div>
              <input
                type="submit"
                id="sF"
                className="sF"
                value="Anmelden - let's win the lottery!"
                onClick={this.submitForm}
              />
            </div>
          </div>
        </div>
      );
  }
}

export default LoginPage;
