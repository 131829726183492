import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Menu from "./components/Menu";
import NeuerTippschein from "./components/NeuerTippschein";
import LoginPage from "./components/LoginPage";
import LogoutPage from "./components/LogoutPage";

import "./App.css";
import axios from "axios";

class App extends Component {
  state = {
    lotto: {
      max: 15,
      anz: 3,
      cost: 1000
    },
    currency: "ggCoins",
    loggedIn: false,
    uid: null,
    user: { balance: 0 },
    aktTipps: [],
    myWins: [],
    lastDraw: [],
    winData: {
      labels: ["", "", "", "", "", "", ""],
      datasets: [
        {
          label: "Last 30 wins",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: [65, 59, 80, 81, 56, 55, 40]
        }
      ]
    }
  };

  loadDashboardData = () => {
    const uid = localStorage.getItem("sia2019_uid");
    //1. Get User Information
    axios
      .get("https://sia.gymgmunden.at/rest.php", {
        params: { trigger: "getUser", sia_uid: uid }
      })
      .then(res => {
        console.log("getUser, res", res);
        this.setState({
          uid: res.data.data.user.sid,
          user: res.data.data.user
        });
      })
      .catch(err => {
        console.log("getUser err", err);
      });
    //2. Get current tipps
    axios
      .get("https://sia.gymgmunden.at/rest.php", {
        params: { trigger: "getCurrentTipps", sia_uid: uid }
      })
      .then(res => {
        console.log("getCurrentTipps", res.data.data.tipps);
        this.setState({ aktTipps: res.data.data.tipps }, () =>
          console.log(this.state.aktTipps)
        );
      })
      .catch(err => {
        console.log("getCurrentTipps err", err);
      });
    //3. Get last wins
    axios
      .get("https://sia.gymgmunden.at/rest.php", {
        params: { trigger: "getLastWins", sia_uid: uid }
      })
      .then(res => {
        console.log("getLastWins", res);
        this.setState({
          myWins: res.data.data.wins,
          lastDraw: res.data.data.lastdraw.lastDraw
        });
      })
      .catch(err => {
        console.log("getCurrentTipps err", err);
      });
  };
  updateDashboardData = () => {
    console.log("updateDashboardData before loadData");
    this.loadDashboardData();
    console.log("updateDashboardData after loadData");
  };
  onLoginSuccess = () => {
    this.setState({ loggedIn: true }, this.loadDashboardData());
  };

  onLogoutSuccess = () => {
    localStorage.removeItem("sia2019_uid");
    localStorage.removeItem("sia2019_upw");
    this.setState({ loggedIn: false });
  };

  render() {
    return (
      <Router>
        <div className="App">
          <Route path="/" component={Menu} />
          <Switch>
            <Route
              path="/"
              exact
              component={() => {
                return (
                  <Dashboard {...this.state} reload={this.loadDashboardData} />
                );
              }}
            />
            <Route
              path="/neuerTipp"
              exact
              component={() => {
                return (
                  <NeuerTippschein
                    {...this.state}
                    updateDashboardData={this.updateDashboardData}
                  />
                );
              }}
            />
            <Route
              path="/login"
              exact
              component={() => {
                return (
                  <LoginPage {...this.state} onLogin={this.onLoginSuccess} />
                );
              }}
            />
            <Route
              path="/logout"
              exact
              component={() => {
                return (
                  <LogoutPage {...this.state} onLogout={this.onLogoutSuccess} />
                );
              }}
            />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
