import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Menu extends Component {
  state = {};
  render() {
    return (
      <div className="Menu">
        <NavLink to="/" className="home">
          SIA'19 <i className="fas fa-home" />
        </NavLink>
        <NavLink to="neuerTipp">Neuer Tipp</NavLink>
        <NavLink to="logout">LogOut</NavLink>
      </div>
    );
  }
}

export default Menu;
