import React, { Component } from "react";

class Zahl extends Component {
  render() {
    return (
      <div
        className={
          this.props.neuerTipp.indexOf(this.props.value) !== -1
            ? "cssZahlChosen"
            : "cssZahl"
        }
        onClick={this.props.onToggle}
        data-number={this.props.value}
      >
        {this.props.value}
      </div>
    );
  }
}

export default Zahl;
