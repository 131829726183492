import React, { Component } from "react";

class Kugel extends Component {
  render() {
    //console.log("Kugel props", this.props);
    return <div className="Kugel">{this.props.number}</div>;
  }
}

export default Kugel;
