import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class LogoutPage extends Component {
  componentDidMount = () => {
    this.props.onLogout();
  };

  render() {
    return <Redirect to="/login" />;
  }
}

export default LogoutPage;
